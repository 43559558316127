import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import PostCard from 'components/posts/post-card';

import 'components/sliders/styles/posts-slider.scss';
import SliderSlidesDots from './slider-slides-dots';

const PostsSlider = ({ posts }) => {
  const [itemIndex, setItemIndex] = useState(0);

  return  (<div className="posts-slider"> <SwipeableViews
    onChangeIndex={ i => setItemIndex(i) }
    className="products-swipeable-root"
    style={ {
      overflowX: 'visible',
      padding: '0px calc(42% - 128px)',
    } }
    slideClassName="card-slide"
    springConfig={ {
      easeFunction: 'cubic-bezier(0, 0, 0.24, 0.96)',
      duration: '.8s',
      delay: '0s',
    } }
    index={ itemIndex }
    enableMouseEvents
  >
    {posts.map(post => {
      const card = {
        slug: `/blog/${post.node.slug}`,
        source_url: post.node.featured_media.source_url,
        date: post.node.date,
        name: post.node.categories[0].name,
        title: post.node.title,
      };
      return <PostCard key={ card.slug } card={ card } />;
    })}
  </SwipeableViews>
  <SliderSlidesDots
    activeIndex={ itemIndex }
    count={ posts.length }
    onSelect={ index => setItemIndex(index) }
  />
  </div>
  );
   
};

export default PostsSlider;