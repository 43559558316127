import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FontIcon from 'components/icons';
import Link from 'components/link/link';
import PostCard from 'components/posts/post-card';
import PostsSlider from 'components/sliders/posts-slider';

import 'components/content-blocks/styles/blog-roll-category.scss';

type BlogRollCategoryProps = {
  posts: any,
  slug?: string,
  title: string
}

const BlogRollCategory = ({ title, posts, slug }:BlogRollCategoryProps) => {
  const breakpoints = useBreakpoint();

  return (
    <section className="section blog-roll-category-section">
      <div className="container">
        <header className="blog-roll-category-header">
          <h2 className="blog-roll-category-title">{title}</h2>
          {slug !== undefined && <Link className="blog-roll-category-link" to={ `/blog/${slug}` }>View All <FontIcon icon="arrow-right" /></Link>}
        </header>

        <div className="blog-roll-category columns">
          {!breakpoints.sm && posts.map(post => {

            const filteredCategories = post.node.categories.filter(category => category.name !== 'Newsletter' && category.name !== 'Articles');
            const name = filteredCategories.length > 0 ? filteredCategories[0].name : '';

            const card = {
              slug: `/blog/${post.node.slug}`,
              source_url: post.node.featured_media.source_url,
              date: post.node.date,
              name: name,
              title: post.node.title,
            };
            return <PostCard key={ card.slug } card={ card } />;
          })}
          {breakpoints.sm && <PostsSlider posts={ posts } />}
        </div>
      </div>
    </section>
  );
};

export default BlogRollCategory;