import { range } from 'lodash';

type SliderSlidesDotsProps = {
  activeIndex: number
  count: number
  onSelect: (index: number) => void
}
const SliderSlidesDots = (props:SliderSlidesDotsProps) => {
  return (
    <div className="slider-dots">
      {range(0, props.count).map(index => (
        <div
          key={ index }
          className={ `slider-dot ${props.activeIndex === index ? 'slider-dot-filled' : ''}` }
          onClick={ () => props.onSelect(index) }
        />
      ))}
    </div>
  );
};

export default SliderSlidesDots;
