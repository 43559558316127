import { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/blog';

import useOnScreen from 'utils/hooks/use-onscreen';

import BlogRollCategory from 'components/content-blocks/blog-roll-category';
import Yoast from 'components/globals/yoast';
import { HeroBlogFeaturedArticle } from 'components/hero/blog-featured-article';
import ScrollToTop from 'components/scroll-to-top/scroll-to-top';

export default function BlogPosts() {

  const element = useRef(null);
  const isVisible = useOnScreen(element);

  const data = useStaticQuery(graphql`
    {
      newBlogPosts:allWordpressPost(
        limit: 3
        sort: {order: ASC, fields: categories___parent_element___parent_element___posts}
        filter: {categories: {elemMatch: {name: {nin: ["Inline Cta", "Contest Terms"]}}}}
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }
      businessGrowthCategory:allWordpressPost(
        limit: 3
        sort: {order: ASC, fields: categories___parent_element___name}
        filter: {categories: {elemMatch: {name: {eq: "Business Growth"}}}}
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }
      productivityCategory:allWordpressPost(
        limit: 3
        sort: {order: ASC, fields: categories___parent_element___name}
        filter: {categories: {elemMatch: {name: {eq: "Productivity"}}}}
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }
      schedulingStrategyCategory:allWordpressPost(
        limit: 3
        sort: {order: ASC, fields: categories___parent_element___name}
        filter: {categories: {elemMatch: {name: {eq: "Scheduling Strategy"}}}}
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }
      payrollResourcesCategory:allWordpressPost(
        limit: 3
        sort: {order: ASC, fields: categories___parent_element___name}
        filter: {categories: {elemMatch: {name: {eq: "Payroll Resources"}}}}
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }
      templatesResourcesCategory:allWordpressPost(
        limit: 3
        sort: {order: ASC, fields: categories___parent_element___name}
        filter: {categories: {elemMatch: {name: {eq: "Templates &amp; Resources"}}}}
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }
      forYourIndustryCategory:allWordpressPost(
        limit: 3
        sort: {order: ASC, fields: categories___parent_element___name}
        filter: {categories: {elemMatch: {name: {eq: "For Your Industry"}}}}
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }
      pressRoomCategory:allWordpressPost(
        limit: 3
        sort: {order: ASC, fields: categories___parent_element___name}
        filter: {categories: {elemMatch: {name: {eq: "Press Room"}}}}
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }
      productBlogCategory:allWordpressPost(
        limit: 3
        sort: {order: ASC, fields: categories___parent_element___name}
        filter: {categories: {elemMatch: {name: {eq: "Product Blog"}}}}
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }
      peopleManagementCategory:allWordpressPost(
        limit: 3
        sort: {order: ASC, fields: categories___parent_element___name}
        filter: {categories: {elemMatch: {name: {eq: "People Management"}}}}
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }
      leadershipCategory:allWordpressPost(
        limit: 3
        sort: {order: ASC, fields: categories___parent_element___name}
        filter: {categories: {elemMatch: {name: {eq: "Leadership"}}}}
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }
      allWordpressPage(filter: { slug: { eq: "blog" } }) {
        edges {
          node {
            wordpress_id
            acf {
              featured_article_static {
                background_image {
                  desktop {
                    source_url
                  }
                  mobile {
                    source_url
                  }
                }
                title
                copy
                link_url
                link_text
                categories {
                  category_link
                  category_title
                }
              }
            }
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
          }
        }
      }
    }
  `);
  
  const newBlogPosts = data.newBlogPosts.edges;
  const businessGrowthCategoryPosts = data.businessGrowthCategory.edges;
  const productivityCategoryPosts = data.productivityCategory.edges;
  const schedulingStrategyCategoryPosts = data.schedulingStrategyCategory.edges;
  const payrollResourcesCategoryPosts = data.payrollResourcesCategory.edges;
  const templatesResourcesCategoryPosts = data.templatesResourcesCategory.edges;
  const forYourIndustryCategoryPosts = data.forYourIndustryCategory.edges;
  const pressRoomCategoryPosts = data.pressRoomCategory.edges;
  const productBlogCategoryPosts = data.productBlogCategory.edges;
  const peopleManagementCategoryPosts = data.peopleManagementCategory.edges;
  const leadershipCategoryPosts = data.leadershipCategory.edges;
  const pageContent = data.allWordpressPage;
  const featuredArticle = data.allWordpressPage.edges[0].node.acf.featured_article_static;

  return (
    <Layout>
      <Yoast { ...pageContent.edges[0].node.yoast_head_json } />
      <div className="blog-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroBlogFeaturedArticle { ...featuredArticle } ref={ element } />
        <BlogRollCategory title="New On The Blog" posts={ newBlogPosts } />
        <BlogRollCategory title="Business Growth" posts={ businessGrowthCategoryPosts } slug="business-growth"  />
        <BlogRollCategory title="Productivity" posts={ productivityCategoryPosts } slug="productivity" />
        <BlogRollCategory title="Scheduling Strategy" posts={ schedulingStrategyCategoryPosts } slug="scheduling-strategy" />
        <BlogRollCategory title="Payroll Resources" posts={ payrollResourcesCategoryPosts } slug="payroll-resources" />
        <BlogRollCategory title="Templates & Resources" posts={ templatesResourcesCategoryPosts } slug="templates-resources" />
        <BlogRollCategory title="For Your Industry" posts={ forYourIndustryCategoryPosts } slug="for-your-industry" />
        <BlogRollCategory title="Press Room" posts={ pressRoomCategoryPosts } slug="press-room" />
        <BlogRollCategory title="Product Blog" posts={ productBlogCategoryPosts } slug="product-blog" />
        <BlogRollCategory title="People Management" posts={ peopleManagementCategoryPosts } slug="people-management" />
        <BlogRollCategory title="Leadership" posts={ leadershipCategoryPosts } slug="leadership" />
        <ScrollToTop className={ `${!isVisible ? 'is-viewable' : ''}` } />
      </div>
    </Layout>
  );
}
